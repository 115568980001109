<template>
    <div class="mobile_Report">
        <div class="page-header flex-align-between" v-if="!$route.query.way">
            <div class="goback flex-align-center" @click="goback">
                <img src="../../assets/newReport/goback.png" alt="">
            </div>
            <div class="title">报告详情</div>
            <!-- <div class="goback"></div> -->
        </div>
        <div class="PersonalInformation flex-column-center">
            <div class="Report_title">{{ userInfo.userName }}同学{{ reportName }}报告</div>
            <div class="semester">学期：{{ $route.query.termKeyName }} 班级：{{ userInfo.className }} </div>
        </div>
        <div class="mainbody">
            <div class="examLevel">
                <div class="Level_title">CCPT书画等级考试推荐：<span style="color: #FF9900;">{{ ccptLevel }}</span>
                </div>
                <div class="Level_content">
                    <div class="Level_img flex-column-center">
                        <div class="Level_text">{{ $route.query.termKeyName }}{{ reportList[$route.query.reportValue]
                        }}：<span>{{ status[userComment.integrationLevel] }}</span></div>
                        <div class="Level_nationwide_Ranking">恭喜你：已经打败全国{{ rankNumber }}%的同学!!!</div>
                    </div>
                    <div class="Level_decoration"></div>
                </div>
                <div class="eachDimension">
                    <table class="styled-table">
                        <tbody>
                            <tr>
                                <td v-for="(row, rowIndex) in tableData.slice(0, 4)" :key="rowIndex">{{ columnsName[row] }}
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(row, rowIndex) in tableData.slice(0, 4)" :key="rowIndex">{{
                                    computeLevel(tableValue[row]) }}
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(row, rowIndex) in tableData.slice(4)" :key="rowIndex">{{ columnsName[row] }}
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(row, rowIndex) in tableData.slice(4)" :key="rowIndex">{{
                                    computeLevel(tableValue[row]) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="imageText" v-for="(item, index) in wordList" :key="index">
                    <div class="imageTable">
                        <div class="orderStrokes_image">
                            <img src="@/assets/assistant/tian.png" />
                            <img :src="item.wordShadowCover" />
                            <img :src="item.resFilename" />
                            <img :src="item.wordSkillCover" />
                        </div>
                        <div class="orderStrokes_table">
                            <table>
                                <tr v-for="(row, i) in item.Dimension" :key="i">
                                    <td v-for="(rowSon, iSon) in row">{{ columnsName[rowSon.name] }}：{{ computeLevel(rowSon.num) }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="Divider"></div>
                    <div class="Comment">
                        <div class="Comment_title">
                            <div class="Comment_title_text">点评</div>
                            <div class="Comment_title_decoration"></div>
                        </div>
                        <div class="Comment_content">
                            <ul>
                                <li v-for="(itemSon, indexSon) in  JSON.parse(item.remark)" :key="indexSon">
                                    <!-- @click="preview(itemSon.urls, 'multiple')" -->
                                    <!-- :class="{ ImgHighlight: itemSon.urls }" -->
                                    <span>{{ indexSon + 1 + '、' }}{{ itemSon.sug }}</span>
                                    <!-- @click="showImagePreview(item.urls)" -->
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="Preview_legend">
                    <div class="Comment">
                        <div class="Comment_title">
                            <div class="Comment_title_text">查看试卷</div>
                            <div class="Comment_title_decoration"></div>
                        </div>
                        <div class="Comment_content Comment_content_url">
                            <ul>
                                <li>
                                    <span @click="preview(workList, 'single')">查看试卷</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layer flex-align-center" v-if="dialogVisible">
            <div class="slider_box ">
                <slider ref="slider" :options="options" @slide='slide' @tap='onTap' @init='onInit'>
                    <slideritem v-for="(item, index) in someList" :key="index" :style="item.style">
                        <img class="Previewimg" :src="item" alt="">
                    </slideritem>
                    <div slot="loading">loading...</div>
                </slider>
            </div>
            <div class="icon">
                <img src="../../assets/live/close.png" alt="" @click="close">
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { slider, slideritem } from 'vue-concise-slider'
export default {
    data() {
        return {
            tableData: [
                [
                    {
                        type: 'name',
                        text: "作品完整度"
                    },
                    {
                        type: 'name',
                        text: "居中占位"
                    },
                    {
                        type: 'name',
                        text: "占格大小"
                    },
                    {
                        type: 'name',
                        text: "宇形重心"
                    }
                ],
                [
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    }
                ],
                [
                    {
                        type: 'name',
                        text: "组合关系"
                    },
                    {
                        type: 'name',
                        text: "收放关系"
                    },
                    {
                        type: 'name',
                        text: "多画等距"
                    },
                    {
                        type: 'name',
                        text: "笔画关系"
                    }
                ],
                [
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    },
                    {
                        type: 'grade',
                        text: "优秀"
                    }
                ],
            ],
            tableValue: {},
            dialogVisible: false,
            someList: [],
            options: {
                pagination: true,
                thresholdDistance: 100,
                thresholdTime: 300,
                grabCursor: true,
                speed: 300
            },
            userInfo: {
                userId: '',
                userName: '',
                schoolName: '',
                className: '',
            },
            reportName: '',
            status: {
                '4': '优秀',
                '3': '良好',
                '2': '合格',
                '1': '待提升'
            },
            userComment: '',
            reportList: {
                1: '前测',
                2: '后测',
                4: '月测',
                5: '周测',
            },
            rankNumber: '',
            ccptLevel: '',
            wordList: [],
            integrity: '',
            workList: [],
            scrollX: 0,
            scrollY: 0,
            columnsName: {
                'integrity': '作品完整度',
                'centerPos': '居中占位',
                'gridSize': '占格大小',
                'glyphGrav': '字形重心',
                'rewindRel': '收放关系',
                'multiDist': '多画等距',
                'strokeRel': '笔画关系',
                'comboRel': '组合关系',
            },
        }
    },
    components: {
        slider,
        slideritem
    },
    methods: {
        async getStuData() {
            let data = {
                termKey: this.$route.query.termKey, // 20232,
                userId: this.$route.query.userId, // 390990,
                examType: this.$route.query.reportValue, // 1前测 2后测 4月测
                testId: this.$route.query.monthValue,
            }
            let resData = await this.$Api.Report.getindRreport(data);
            this.userInfo = resData;
            this.reportName = resData.testName;
            this.userComment = resData.userComment;
            this.rankNumber = resData.rankNumber;
            this.ccptLevel = resData.ccptLevel;
            this.integrity = resData.userComment.comment;
            this.tableData = resData.dimCodes;
            this.tableValue = {
                'integrity': resData.userComment.integrity,
                'centerPos': resData.userComment.centerPos,
                'gridSize': resData.userComment.gridSize,
                'glyphGrav': resData.userComment.glyphGrav,
                'rewindRel': resData.userComment.rewindRel,
                'multiDist': resData.userComment.multiDist,
                'strokeRel': resData.userComment.strokeRel,
                'comboRel': resData.userComment.comboRel,
            };
            this.workList = resData.imgList;
            this.wordList = resData.wordList;
            let that = this;
            for (let i = 0; i < this.wordList.length; i++) {
                this.wordList[i].Dimension = [
                    {
                        name: 'centerPos',
                        num: this.wordList[i].centerPos,
                    },
                    {
                        name: 'gridSize',
                        num: this.wordList[i].gridSize,
                    },
                    {
                        name: 'glyphGrav',
                        num: this.wordList[i].glyphGrav,
                    },
                    {
                        name: 'rewindRel',
                        num: this.wordList[i].rewindRel,
                    },
                    {
                        name: 'multiDist',
                        num: this.wordList[i].multiDist,
                    },
                    {
                        name: 'strokeRel',
                        num: this.wordList[i].strokeRel,
                    },
                    {
                        name: 'comboRel',
                        num: this.wordList[i].comboRel,
                    },
                ]

            };
            for (let i = 0; i < this.wordList.length; i++) {
                this.wordList[i].Dimension = this.wordList[i].Dimension.filter(item => item.num !== -1);
                this.wordList[i].Dimension = this.wordList[i].Dimension.reduce((acc, val, index) => {
                    const chunkIndex = Math.floor(index / 2);
                    if (!acc[chunkIndex]) {
                        acc[chunkIndex] = [];
                    }

                    acc[chunkIndex].push(val);

                    return acc;
                }, []);
            };
            console.log('wordList', this.wordList)

        },
        computeLevel(score) {
            if (score >= 85) {
                return '优秀'
            }
            if (score >= 70 && score < 85) {
                return '良好'
            }
            if (score >= 60 && score < 70) {
                return '合格'
            }
            if (score < 60 && score != -1) {
                return '待提升'
            }
            if (score == -1) {
                return '无'
            }
        },
        preview(url, type) {
            // console.log('url', url);
            if (type == 'single') {
                this.someList = url;
                // this.someList = [url];
            } else {
                this.someList = url;
            }
            // this.someList = this.imges;
            this.dialogVisible = true;
            // document.body.style.overflow = 'hidden';
            this.Disablescroll_bar();
        },
        close() {
            this.dialogVisible = false;
            this.enableScroll();
            // document.body.style.overflow = '';
        },
        slide() {
            // console.log(document);
            // document.body.classList.add('no-scroll');

        },
        onTap() {

        },
        onInit() {

        },
        Disablescroll_bar() {
            this.scrollX = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
            this.scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // console.log(`Scroll X: ${scrollX}, Scroll Y: ${scrollY}`);
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
            // document.body.style.top = '0';
            document.body.style.left = '0';
            document.body.style.right = '0';
            document.body.style.bottom = '0';
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '17px';

        },
        enableScroll() {
            document.body.style.position = '';
            document.body.style.width = '';
            document.body.style.top = '';
            document.body.style.left = '';
            document.body.style.right = '';
            document.body.style.bottom = '';
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
            document.documentElement.scrollTop = this.scrollY;

        },
        goback() {
            this.$router.go(-1);
        },

    },
    mounted() {
        this.getStuData();

        // this.currentTime = moment().format("YYYY.MM.DD");  
    }
}
</script>
<style lang="less" scoped>
@import url('./less/new_report_stu_zhushou_move.less');

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.block {
    width: 120px;
    height: 120px;
    background-color: #fff;
}
</style>
